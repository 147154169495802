

@import "./support/support";
@import "./custom/setup";
@import "./color_schemes/light";

@import "./modules";
div.opaque {
  background-color: $body-background-color;
}p.definition, blockquote.definition {
    background: rgba($grey-dk-000, 0.2);
    border-left: $border-radius solid $grey-dk-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $grey-dk-300;
        content: "Definition";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .definition-title {
      color: $grey-dk-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.definition-title, blockquote.definition-title {
    background: rgba($grey-dk-000, 0.2);
    border-left: $border-radius solid $grey-dk-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $grey-dk-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.definition {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.definition-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.theorem, blockquote.theorem {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $green-300;
        content: "Theorem";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .theorem-title {
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.theorem-title, blockquote.theorem-title {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.theorem {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.theorem-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.proposition, blockquote.proposition {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $green-300;
        content: "Proposition";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .proposition-title {
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.proposition-title, blockquote.proposition-title {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.proposition {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.proposition-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.lemma, blockquote.lemma {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $green-300;
        content: "Lemma";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .lemma-title {
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.lemma-title, blockquote.lemma-title {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.lemma {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.lemma-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.corollary, blockquote.corollary {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $green-300;
        content: "Corollary";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .corollary-title {
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.corollary-title, blockquote.corollary-title {
    background: rgba($green-000, 0.2);
    border-left: $border-radius solid $green-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $green-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.corollary {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.corollary-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

p.axiom, blockquote.axiom {
    background: rgba($yellow-000, 0.2);
    border-left: $border-radius solid $yellow-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    
    &::before {
        color: $yellow-300;
        content: "Axiom";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: .75em;
        padding-bottom: .125rem;
    }
    
    > .axiom-title {
      color: $yellow-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

p.axiom-title, blockquote.axiom-title {
    background: rgba($yellow-000, 0.2);
    border-left: $border-radius solid $yellow-300;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 3px 10px rgba(0, 0, 0, 0.08);
    padding: .8rem;
    > p:first-child {
      margin-top: 0;
      margin-bottom: 0;
      color: $yellow-300;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      font-size: .75em;
      padding-bottom: .125rem;
    }
}

blockquote.axiom {
  margin-left: 0;
  margin-right: 0;
  
  > p:first-child {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}

blockquote.axiom-title {
  margin-left: 0;
  margin-right: 0;
  
  > p:nth-child(2) {
    margin-top: 0;
  }
    
  > p:last-child {
    margin-bottom: 0;
  }
}


@import "./custom/custom";


